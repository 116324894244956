import {
    IConsultationDto,
    IRateConsultationRequest,
} from '../../Common/interfaces';
import { fetch } from '@/Common/fetch';
import { Texts } from '../../Common/Texts';
import { AlertViewModel } from '../AlertViewModel';
import { ReceiveVideoCall } from './ReceiveVideoCallFromDoctor';

export class RateConsultation {
    public receiveVideoCall: ReceiveVideoCall;

    public rating: KnockoutObservable<number>;
    public comment: KnockoutObservable<string>;
    public isSubmitting: KnockoutObservable<boolean>;
    public feedbackTitle: KnockoutComputed<string>;
    public feedbackText: KnockoutComputed<string>;
    public messages: { title: string; text: string }[];
    private consultationUid: string;
    private alertModal: AlertViewModel;

    constructor(consultation: IConsultationDto) {
        this.messages = [
            {
                title: Texts.get('Darn'),
                text: Texts.get('SorryForYourConcerns'),
            },
            {
                title: Texts.get('Darn'),
                text: Texts.get('SorryForYourConcerns'),
            },
            {
                title: Texts.get('ThankYou'),
                text: Texts.get('ImproveOurselves'),
            },
            {
                title: Texts.get('Great'),
                text: Texts.get('BigHelp'),
            },
            {
                title: Texts.get('Great'),
                text: Texts.get('BigHelp'),
            },
        ];

        this.consultationUid = consultation.UID;
        this.alertModal = new AlertViewModel();
        this.receiveVideoCall = new ReceiveVideoCall();

        this.rating = ko.observable(consultation.Rating);
        this.comment = ko.observable(consultation.RatingComment);
        this.isSubmitting = ko.observable(false);

        this.feedbackTitle = ko.computed(() => {
            const value = this.rating();
            const isValid = value >= 1 && value <= this.messages.length;
            return isValid ? this.messages[value - 1].title : '';
        });

        this.feedbackText = ko.computed(() => {
            const value = this.rating();
            const isValid = value >= 1 && value <= this.messages.length;
            return isValid ? this.messages[value - 1].text : '';
        });

        this.rating.subscribe(async (newValue) => {
            try {
                await this.saveRating();
            } catch (error) {
                // fails quietly
            }
        });
    }

    async handleSubmit() {
        try {
            await this.saveRating();
            window.location.href =
                window.location.origin + '/consultations/rateconfirmation';
        } catch (error) {
            this.alertModal.openAlert(Texts.getResource('ErrorRate'));
        }
    }

    public async saveRating(): Promise<void> {
        if (this.isSubmitting()) {
            return;
        }
        this.isSubmitting(true);

        const request: IRateConsultationRequest = {
            Rating: this.rating(),
            RatingComment: this.comment(),
        };

        try {
            await fetch(
                `/api/consultations/${this.consultationUid}/rate`,
                'POST',
                request,
                'application/json',
            );
            this.isSubmitting(false);
        } catch (error) {
            this.isSubmitting(false);
            throw error;
        }
    }
}
